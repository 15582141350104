<template>
  <b-container fluid>

    <vue-headful title="Reset Password | FOMS" />

    <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="6">
        <div>
          <b-card class="overflow-hidden text-left" >
            <b-row>
              <b-col align-self="center" cols="2">
                <b-card-img src="/favicon.png" class="rounded-0" style="max-width: 100px"></b-card-img>
              </b-col>
              <b-col cols="10">
                <b-card-body title="Field Operations Management System - FOMS">
                  <b-card-text>

                    <b-form @submit.stop.prevent @submit="onSubmit" class="mt-5">

                        <input type="hidden" v-model="credentials.token" />

                        <!-- Email -->
                        <b-form-group id="email-group" label-for="email" label="" description="">
                            <b-form-input id="email" v-model="credentials.email"
                              type="email" placeholder="Email address"
                              aria-describedby="input-live-email-feedback"
                              :state="usernameGood" required>
                            </b-form-input>

                            <!-- This will only be shown if the preceding input has an invalid state -->
                            <b-form-invalid-feedback id="input-live-email-feedback">
                              Email is invalid.
                            </b-form-invalid-feedback>

                        </b-form-group>

                        <!-- Password -->
                        <b-form-group id="pass-group" label-for="pass" label="" description="">
                            <b-form-input id="pass" v-model="credentials.password"
                              type="password" placeholder="Password"
                              aria-describedby="input-live-pass-feedback"
                              :state="passwordGood" required>
                              </b-form-input>

                            <!-- This will only be shown if the preceding input has an invalid state -->
                            <b-form-invalid-feedback id="input-live-pass-feedback">
                              Invalid password. Password must be 6 or more characters
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- Password Confirmation  -->
                        <b-form-group id="pass-confirm-group" label-for="pass_confirm" label="" description="">
                            <b-form-input id="password_confirmation" v-model="credentials.password_confirmation"
                              type="password" placeholder="Confirm password"
                              aria-describedby="input-live-pass-confirm-feedback"
                              :state="passwordConfirmGood" required>
                              </b-form-input>

                            <b-form-invalid-feedback id="input-live-pass-confirm-feedback">
                              Password confirmation does not match password.
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-button  type="submit" variant="primary" :disabled="!canResetPassword">
                          <b-spinner v-show="busy" small></b-spinner> Reset
                        </b-button>
                      </b-form>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>

            <!-- Login -->
            <b-row align-content="start">
              <b-col cols="2"></b-col>
              <b-col class="ml-3">
                  <b-button variant="link" class="small muted" @click="goToLogin()">Go to login.</b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>

  </b-container>

</template>

<script>
import Vue from 'vue'
import vueHeadful from 'vue-headful'
import authService from '../../api/auth'

Vue.component('vue-headful', vueHeadful)

export default {
  name: 'Reset',
  props: ['token'],
  data () {
    return {
      credentials: {
        email: '',
        password: '',
        password_confirmation: '',
        token: this.token
      },
      busy: false,
      usernameGood: null,
      passwordGood: null,
      passwordConfirmGood: null
    }
  },
  computed: {
    canResetPassword: function () {
      return this.credentials.email !== '' && this.credentials.password !== '' && (this.credentials.password === this.credentials.password_confirmation)
    }
  },
  methods: {
    onSubmit () {
      let self = this
      self.busy = true
      authService.reset_password(this.credentials)
        .then((response) => self.success(response))
        .catch((errors) => self.failure(errors))
    },

    goToLogin () {
      this.$router.push('/auth/login')
    },
    success (response) {
      this.busy = false
      try {
        if (response.hasOwnProperty('error')) {
          this.makeToast('danger', 'An Error Occured', response.error)
        } else {
          this.makeToast('success', 'Success', 'Password reset')
          this.goToLogin()
        }
      } catch (e) {
        this.syslog(e)
      }
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: 'b-toaster-top-center',
        variant: variant,
        solid: true
      })
    },

    failure (errors) {
      this.busy = false
      try {
        if (errors.hasOwnProperty('errors')) {
          this.usernameGood = !(errors.errors.hasOwnProperty('email'))
          if (errors.errors.hasOwnProperty('password') && !errors.message.includes('confirm')) {
            this.passwordGood = false
          }
          if (errors.errors.hasOwnProperty('password') && errors.message.includes('confirm')) {
            this.passwordConfirmGood = false
          }
        } else if (errors.hasOwnProperty('status') && errors.status === 'error') {
          this.makeToast('danger', 'An Error Occured', errors.message)
        }
      } catch (e) {
        this.syslog(e)
      }
    }
  }
}
</script>
